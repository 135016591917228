import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
    ModalController,
} from '@ionic/angular/standalone';

import { backIcon } from '../components/back-icon';

@Component({
    selector: 'bv-popup-modal',
    templateUrl: './error-modal.component.html',
    styleUrl: './error-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent, IonFooter],
})
export class ErrorModalComponent {
    readonly backText: string = 'Terug';
    readonly header: string;
    readonly title: string | null;
    readonly message: string;
    protected readonly backIcon = backIcon;
    private modalController = inject(ModalController);

    close() {
        this.modalController.dismiss();
    }
}
